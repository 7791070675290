import './utils/adaption'
import './App.scss'


function App() {
  let arr = [
    { img: require('./assets/imgs/1.png'), hrefid: 'index' },
    { img: require('./assets/imgs/2.png'), hrefid: 'brandStory' },
    { img: require('./assets/imgs/3.png') },
    { img: require('./assets/imgs/4.png') },
    { img: require('./assets/imgs/5.png') },
    { img: require('./assets/imgs/6.png') },
    { img: require('./assets/imgs/7.png') },
    { img: require('./assets/imgs/8.png') },
    { img: require('./assets/imgs/9.png') },
    { img: require('./assets/imgs/10.png') },
    { img: require('./assets/imgs/11.png') },
    { img: require('./assets/imgs/12.png') },
    { img: require('./assets/imgs/13.png') },
    { img: require('./assets/imgs/14.png') },
    { img: require('./assets/imgs/15.png'), hrefid: 'scientificResearchStrength' },
    { img: require('./assets/imgs/16.png') },
    { img: require('./assets/imgs/17.png') },
    { img: require('./assets/imgs/18.png') },
    { img: require('./assets/imgs/19.png') },
    { img: require('./assets/imgs/20.png') },
    { img: require('./assets/imgs/21.png') },
    { img: require('./assets/imgs/22.png') },
    { img: require('./assets/imgs/23.png') },
    { img: require('./assets/imgs/24.png') },
    { img: require('./assets/imgs/25.png') },
    { img: require('./assets/imgs/26.png') },
    { img: require('./assets/imgs/27.png') },
    { img: require('./assets/imgs/28.png') },
    { img: require('./assets/imgs/29.png') },
    { img: require('./assets/imgs/30.png') },
    { img: require('./assets/imgs/31.png') },
    { img: require('./assets/imgs/32.jpg') },
    { img: require('./assets/imgs/33.jpg') },
    { img: require('./assets/imgs/34.jpg') },
    { img: require('./assets/imgs/35.jpg') },
    { img: require('./assets/imgs/36.jpg') },
    { img: require('./assets/imgs/37.jpg') },
    { img: require('./assets/imgs/38.jpg') },
    { img: require('./assets/imgs/39.jpg') },
    { img: require('./assets/imgs/40.jpg') },
    { img: require('./assets/imgs/41.jpg') },
    { img: require('./assets/imgs/42.jpg') },
    { img: require('./assets/imgs/43.jpg') },
    { img: require('./assets/imgs/44.png') },
    { img: require('./assets/imgs/45.jpg') },
    { img: require('./assets/imgs/46.png') },
    { img: require('./assets/imgs/47.jpg') },
    { img: require('./assets/imgs/48.jpg') },
    { img: require('./assets/imgs/49.jpg') },
    { img: require('./assets/imgs/50.jpg') },
    { img: require('./assets/imgs/51.png') },
    { img: require('./assets/imgs/52.png') },
    { img: require('./assets/imgs/53.png') },
    { img: require('./assets/imgs/54.png') },
    { img: require('./assets/imgs/55.jpg') },
    { img: require('./assets/imgs/56.jpg') },
    { img: require('./assets/imgs/57.jpg') },
    { img: require('./assets/imgs/58.png') },
    { img: require('./assets/imgs/59.png') },
    { img: require('./assets/imgs/60.png') },
    { img: require('./assets/imgs/61.png') },
    { img: require('./assets/imgs/62.png') },
    { img: require('./assets/imgs/logotittle.png') },
  ]

  return (
    <div className="App">
      <div className="header">
        <img src={require('./assets/imgs/logotittle.png')} />
      </div>

      <div className="navigation">
        <a href='#index'>首页</a>
        <a href="#brandStory">产品故事</a>
        <a href="#scientificResearchStrength">科研实力</a>
      </div>

      <div className="img_list">
        {
          arr.map((i, v) => {
            return <img className="img_item" id={i.hrefid ? i.hrefid : ''} key={v} src={i.img} alt="" />
          })
        }
      </div>


      <div className="footer">
        <div>
          <p>约到优品（广州）品牌管理有限公司</p>
          <p>公司地址：广州市天河区黄埔大道西365号1409室P33房</p>
          <p onClick={() => { console.log(window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index') }}>粤ICP备2021159856号</p>
        </div>
      </div>


    </div>
  );
}

export default App;
